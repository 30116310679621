import { Box, Stack, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageWrapper from '../../components/PageWrapper';
import { Context as AppContext } from '../../context/AppContext';
import { componentMapping } from '../../utils/enums';

const ModuleItems = () => {
    const { className, moduleName } = useParams();
    const {state:{modules}} = useContext(AppContext);
    const [module, setModule] = useState([]);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        const module = modules.find(m => m.module === moduleName);
        setModule(module);
        // Reset tab value
        setTabValue(0);
        
        if (module?.components?.length > 0) {
            // Sort components the same way as in the render
            const sortedComponents = [...module.components].sort((a, b) => {
                const isALessonPlan = a.componentName.includes('LessonPlanObjectives');
                const isBLessonPlan = b.componentName.includes('LessonPlanObjectives');
                
                if (isALessonPlan) return -1;
                if (isBLessonPlan) return 1;
                return 0;
            });
            // Set the first component after sorting
            setSelectedComponent(sortedComponents[0]);
        }
    }, [moduleName, modules]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        setSelectedComponent(module.components[newValue]);
    };

    const renderComponent = () => {
        if (!selectedComponent) return null;
        
        const Component = componentMapping[selectedComponent.componentName];
        return Component ? <Component /> : null;
    };

    return (
        <PageWrapper title={module?.title ? module?.title : ''}>
            <Stack spacing={3} sx={{marginTop: 2}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs 
                        value={tabValue} 
                        onChange={handleTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        {module?.components?.sort((a, b) => {
                            // Check if either component name contains "LessonPlanObjectives"
                            const isALessonPlan = a.componentName.includes('LessonPlanObjectives');
                            const isBLessonPlan = b.componentName.includes('LessonPlanObjectives');
                            
                            if (isALessonPlan) return -1;  // a comes first
                            if (isBLessonPlan) return 1;   // b comes first
                            return 0;  // maintain original order
                        }).map((item) => (
                            <Tab 
                                key={item.componentName} 
                                label={item.title}
                            />
                        ))}
                    </Tabs>
                </Box>

                {/* Component render area */}
                <Box>
                    {renderComponent()}
                </Box>
            </Stack>
        </PageWrapper>
    );
};

export default ModuleItems; 