import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, IconButton, Stack, TextField, Typography } from "@mui/material";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import ChatWindow from "./ChatWindow";
import loadingGif from "../../assets/ai-loading.webm";
import SendIcon from "@mui/icons-material/Send";
import { Context as AppContext } from "../../context/AppContext";
import { toast } from "react-toastify";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../config/firebase";
import ClassSelection from "./ClassSelection";
import { enums } from "../../utils/enums";
import { messagesCurriculum, messagesInternship } from "../../utils/enums";
import aidenSmall from "../../assets/small-logo.png";
import { keyframes } from '@mui/system';  // Import keyframes for animation
import { db } from "../../config/firebase"; // Add this import
import { addDoc, collection, doc, setDoc, updateDoc, serverTimestamp, query, where, getDocs, orderBy, limit } from "firebase/firestore"; // Add these imports

// Define the pulse animation instead of spin
const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const AiDesigner = () => {
    const { state: { messages, currentUser }, updateMessages } = useContext(AppContext)
    const getChatCompletion = httpsCallable(functions, "getChatCompletionC");
    const [isAnswering, setIsAnswering] = useState(false);
    const [userQuery, setUserQuery] = useState('');
    const [chatGptUserQuery, setChatGptUserQuery] = useState(null);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedGrade, setSelectedGrade] = useState('');
    const [getResponseFromChat, setGetResponseFromChat] = useState(0);
    const [isInternship, setIsInternship] = useState(currentUser.role === enums.MENTOR);
    const [conversationId, setConversationId] = useState(null);

    useEffect(() => {
        // Load existing conversation or create a new one when component mounts
        loadOrCreateConversation();
    }, [currentUser]);

    useEffect(() => {
        if (getResponseFromChat > 0 && (isInternship || (selectedClass && selectedGrade))) {
            sendChatGptPrompt();
        }
    }, [getResponseFromChat]);

    useEffect(() => {
        if (!chatGptUserQuery) return;

        // Add user's query to the message array before making the request
        messages.push(chatGptUserQuery);
        updateMessages([...messages])
        setUserQuery('');

        getMessages(); // Call the async function

    }, [chatGptUserQuery]);

    useEffect(() => {
        if (selectedClass && selectedGrade) {
            const userQuery = `I want to design a  ${selectedClass} class, for the following grade: ${selectedGrade}`
            setUserQuery(userQuery);
            setGetResponseFromChat(getResponseFromChat + 1);
        }
    }, [selectedClass, selectedGrade]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'Enter') {
                setUserQuery(userQuery);
                setGetResponseFromChat(getResponseFromChat + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const loadOrCreateConversation = async () => {
        try {
            // Check if user has an active conversation
            const conversationsRef = collection(db, "conversations");
            const q = query(
                conversationsRef, 
                where("userId", "==", currentUser.uid),
                where("isActive", "==", true),
                orderBy("createdAt", "desc"),
                limit(1)
            );
            
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                // Use existing conversation
                const conversationDoc = querySnapshot.docs[0];
                setConversationId(conversationDoc.id);
                
                // Load messages if needed
                if (messages.length === 0 || 
                   (isInternship ? messages === messagesCurriculum : messages === messagesInternship)) {
                    const conversationData = conversationDoc.data();
                    if (conversationData.messages && conversationData.messages.length > 0) {
                        updateMessages(conversationData.messages);
                    } else {
                        updateMessages(isInternship ? messagesInternship : messagesCurriculum);
                    }
                }
            } else {
                // Create new conversation
                createNewConversation();
            }
        } catch (error) {
            console.error("Error loading conversation:", error);
            toast.error("Failed to load conversation history");
        }
    };

    const createNewConversation = async () => {
        try {
            const initialMessages = isInternship ? messagesInternship : messagesCurriculum;
            const newConversation = {
                userId: currentUser.uid,
                userEmail: currentUser.email ? currentUser.email : "",
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                isActive: true,
                type: isInternship ? "internship" : "curriculum",
                selectedClass: selectedClass || "",
                selectedGrade: selectedGrade || "",
                messages: initialMessages
            };
            
            const docRef = await addDoc(collection(db, "conversations"), newConversation);
            setConversationId(docRef.id);
            updateMessages(initialMessages);
        } catch (error) {
            console.error("Error creating new conversation:", error);
            toast.error("Failed to create new conversation");
        }
    };

    const clearConversation = async () => {
        try {
            // Mark current conversation as inactive
            if (conversationId) {
                await updateDoc(doc(db, "conversations", conversationId), {
                    isActive: false,
                    updatedAt: serverTimestamp()
                });
            }
            
            // Create a new conversation
            createNewConversation();
            
            toast.success("Started a new conversation");
        } catch (error) {
            console.error("Error clearing conversation:", error);
            toast.error("Failed to clear conversation");
        }
    };

    async function getMessages() {
        try {
            setIsAnswering(true);

            const response = await getChatCompletion({ messages: messages });

            // Firebase callable functions don't have a 'status' field, so check if response.data exists
            if (response.data) {
                const updatedMessages = [...messages, response.data.message];
                updateMessages(updatedMessages);
                setChatGptUserQuery(null);
                
                // Update conversation in Firestore
                if (conversationId) {
                    await updateDoc(doc(db, "conversations", conversationId), {
                        messages: updatedMessages,
                        updatedAt: serverTimestamp()
                    });
                }
            } else {
                toast.error('Something went wrong, please try again!');
            }

        } catch (error) {
            console.error("Error fetching chat completion:", error);
            toast.error('An error occurred, please try again!');
        } finally {
            setIsAnswering(false); // Ensure this runs in both success and error cases
        }
    }

    function sendChatGptPrompt() {
        const userMessage = {
            "role": "user",
            "content": userQuery
        };
        
        setChatGptUserQuery(userMessage);
        
        // Update conversation in Firestore with the user message
        if (conversationId) {
            const updatedMessages = [...messages, userMessage];
            updateDoc(doc(db, "conversations", conversationId), {
                messages: updatedMessages,
                updatedAt: serverTimestamp(),
                // Update class and grade if they've changed
                selectedClass: selectedClass || "",
                selectedGrade: selectedGrade || ""
            }).catch(error => {
                console.error("Error updating conversation with user message:", error);
            });
        }
    }

    return (
        <MainLayout>
            <Box sx={{
                height: 'calc(100% - 60px)',
                overflow: 'hidden',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column",
            }}>
                {!isInternship &&
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignItems={{ xs: 'flex-start', sm: 'center' }}
                        justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                        spacing={2}
                    >
                        <Typography variant={'h3'}>
                            Curriculum Designer
                        </Typography>
                        <Stack direction="row" spacing={2} alignItems="center">
                            <ClassSelection
                                selectedClass={selectedClass}
                                setSelectedClass={setSelectedClass}
                                selectedGrade={selectedGrade}
                                setSelectedGrade={setSelectedGrade}
                            />
                            <Button 
                                variant="outlined" 
                                color="primary" 
                                onClick={clearConversation}
                            >
                                New Conversation
                            </Button>
                        </Stack>
                    </Stack>
                }
                {isInternship && <Stack direction={'row'} alignItems={'center'}
                    justifyContent={"space-between"}
                >
                    <Typography variant={'h3'} sx={{ mr: 2 }}>
                        Internship Designer
                    </Typography>
                    <Button 
                        variant="outlined" 
                        color="primary" 
                        onClick={clearConversation}
                    >
                        New Conversation
                    </Button>
                </Stack>}

                <ChatWindow sx={{ flex: 1 }} />

                <Stack>
                    {/* {isAnswering && (
                        <img 
                            src={aidenSmall} 
                            alt="Aiden Assistant" 
                            style={{
                                width: '20px',
                                animation: `${pulse} 1.5s ease-in-out infinite`  // Apply the pulse animation
                            }}
                        />
                    )} */}
                    <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'center'}
                        sx={{ position: 'relative', bottom: 10, width: '90%', paddingLeft: "50px" }}>
                        {!isInternship && <TextField
                            placeholder={selectedGrade && selectedClass ? "Start typing..." : "Please select both class and grade to chat with Aiden"}
                            multiline rows={3} sx={{ flex: 1, backgroundColor: 'white' }}
                            value={userQuery} onChange={e => setUserQuery(e.target.value)}
                            disabled={!selectedClass || !selectedGrade}
                        // helperText={!selectedClass || !selectedGrade ? "Please select both class and grade to enable this field." : ""}
                        />}
                        {isInternship && <TextField
                            placeholder={"Start typing..."}
                            multiline rows={3} sx={{ flex: 1, backgroundColor: 'white' }}
                            value={userQuery} onChange={e => setUserQuery(e.target.value)}
                        // helperText={!selectedClass || !selectedGrade ? "Please select both class and grade to enable this field." : ""}
                        />}
                        <Box sx={{ textAlign: "right" }}>
                            {
                                isAnswering ?
                                    <video style={{ width: '50px' }} autoPlay loop src={loadingGif} /> :
                                    <IconButton color="primary" variant={"outlined"} sx={{ width: "50px", height: "50px" }}
                                        aria-label="add"
                                        onClick={() => setGetResponseFromChat(getResponseFromChat + 1)}>
                                        <SendIcon />
                                    </IconButton>
                            }
                        </Box>
                    </Stack>
                </Stack>
            </Box>
        </MainLayout>
    );
};

export default AiDesigner;
