import { ThemeProvider } from "@mui/material";
import { onAuthStateChanged } from "firebase/auth";
import { Suspense, useContext, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoadingScreen from "./components/loading-screen/LoadingScreen";
import { auth } from "./config/firebase";
import { Context as AppContext } from "./context/AppContext";
import { MainLayout } from "./layouts/main-layout/MainLayout";
import AiDesigner from "./pages/ai-designer/AiDesigner.js";
import { Idea } from "./pages/idea/Idea";
import Applicants from "./pages/internship/Applicants";
import Homepage from "./pages/internship/Homepage";
import Internship from "./pages/internship/Internship";
import MentorHomepage from "./pages/internship/MentorHomepage";
import Mentors from "./pages/internship/Mentors";
import InternHomepage from "./pages/internship/InternHomepage.js";
import Learning from "./pages/learning/Learning";
import { LoginPage } from "./pages/login/LoginPage";
import { MyIdeas } from "./pages/my-ideas/MyIdeas";
import { Profile } from "./pages/profile/Profile";
import { QuestionsPage } from "./pages/questions/QuestionsPage";
import { RegisterPage } from "./pages/register/RegisterPage";
import { RegisterPageMentor } from "./pages/register/RegisterPageMentor";
import { RegisterPageIntern } from "./pages/register/RegisterPageIntern.js";
// import AiPrompts from "./pages/resource-hub/AiPrompts.js";
// import AssessmentToolkit from "./pages/resource-hub/AssessmentToolkit.js";
import ClassPage from "./pages/resource-hub/ClassPage";
// import LessonPlanObjectives from "./pages/resource-hub/LessonPlanObjectives.js";
// import MetacognitiveExercise from "./pages/resource-hub/MetacognitiveExercise.js";
// import AISetupGuide from "./pages/resource-hub/AISetupGuide";
import ResourceHub from "./pages/resource-hub/ResourceHub";
import { Router } from "./utils/Router";
import { theme } from "./utils/Theme";
import ClassGpt from "./pages/resource-hub/ClassGpt";   
import ClassGptChat from "./pages/resource-hub/ClassGptChat";
import MasterPrompts from "./pages/resource-hub/MasterPrompts";
import ModuleItems from "./pages/resource-hub/ModuleItems";
import {logEvent } from "firebase/analytics";
import {analytics} from "./config/firebase";
function App() {
    const {state: {currentUser}, fetchIdeas, getUserData} = useContext(AppContext)
    const [isAuth, setIsAuth] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setIsAuth(!!user);
            if (user) {
                logEvent(analytics, 'user_login', {
                    user_id: user.uid,
                    email: user.email,
                    method: user.providerData[0].providerId
                });           
                await getUserData();
                await fetchIdeas();
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) return <LoadingScreen/>;

    return (
        <ThemeProvider theme={theme}>
            <ToastContainer/>
            <Suspense fallback={<LoadingScreen/>}>
                <Routes>
                    {!isAuth ? (
                        <>
                            <Route path="/login" element={<LoginPage/>}/>
                            <Route path="/register" element={<RegisterPage/>}/>
                            <Route path="/register/company/:companyName" element={<RegisterPageMentor/>}/>
                            <Route path="/register/intern/" element={<RegisterPageIntern/>}/>
                            <Route path="*" element={<Navigate to="/login"/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<Router/>}/>
                            <Route path="/mentor" element={<MentorHomepage/>}/>
                            <Route path="/intern" element={<InternHomepage/>}/>
                            <Route path="/class-gpt" element={<ClassGpt/>}/>
                            <Route path="/class-gpt/:uid" element={<ClassGptChat/>}/>
                            <Route path="/internship/:id" element={<Internship/>}/>
                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/applicants" element={<Applicants/>}/>
                            <Route path="/mentors" element={<Mentors/>}/>
                            <Route path="/questions" element={<QuestionsPage/>}/>
                            <Route path="/resource-hub" element={<ResourceHub/>}/>
                            <Route path="/master-prompts" element={<MasterPrompts/>}/>
                            <Route path="/resource-hub/:className/:moduleName" element={<ModuleItems/>}/>
                            <Route path="/resource-hub/:className" element={<ClassPage/>}/>
                            <Route path="/my-profile" element={<MainLayout/>}/>
                            <Route path="/my-ideas" element={<MyIdeas/>}/>
                            <Route path="/learning" element={<Learning/>}/>
                            <Route path="/internship" element={<Homepage/>}/>
                            <Route path="/ai-designer" element={<AiDesigner/>}/>
                            <Route path="/ai-internship-designer" element={<AiDesigner/>}/>
                            <Route path="/idea/:id" element={<Idea/>}/>
                            <Route path="*" element={<Router/>}/>
                        </>
                    )}
                </Routes>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;
