import { Avatar, IconButton, Stack, Tab, Tabs, Typography, Fade, Button } from "@mui/material";
import Box from "@mui/material/Box";
import {
    IconArrowLeft,
    IconBook,
    IconFolder,
    IconBookmarks,
    IconPresentation
} from '@tabler/icons-react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import { Context as AppContext } from '../../context/AppContext';
import animationData from '../../assets/aiden boat.json';
import Lottie from "lottie-react";
import LessonContentRenderer from './LessonContentRenderer';
import yourLessonJsonData from './tannya_json.json';

const ClassPage = () => {
    const { className } = useParams();
    const navigate = useNavigate();
    const [keyStage, setKeyStage] = useState(1);
    const [filteredModules, setFilteredModules] = useState([]);
    const { state: { modules, componentMapping } } = useContext(AppContext);
    const [presentationUrl, setPresentationUrl] = useState(null);
    const [isGeneratingPresentation, setIsGeneratingPresentation] = useState(false);
    const [currentLessonData, setCurrentLessonData] = useState(null);
    const functionUrl = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'http://127.0.0.1:5001/aidenapp-73c58/us-central1/createGoogleSlidesPppt' : 'https://us-central1-aidenapp-73c58.cloudfunctions.net/createGoogleSlidesPppt';
    const [selectedLesson, setSelectedLesson] = useState(null);

    const handleKeyStageChange = (event, newValue) => {
        console.log(newValue);
        setKeyStage(newValue);
    };

    function filterModules(keyStage) {
        return modules?.filter(module => module.class === className)
            .filter(module => module.keyStage === keyStage);
    }
    useEffect(() => {
        const filteredModules = filterModules(keyStage);
        console.log(filteredModules);
        setFilteredModules(filteredModules);
    }, [keyStage, modules]);

    const navigateToPage = (module) => {
        console.log(module);
        navigate(`/resource-hub/${className}/${module.module}`);
    }

    useEffect(() => {
        console.log('JSON data:', yourLessonJsonData);
        console.log('Current className:', className);
        console.log('Current keyStage:', keyStage);
        
        // Filter lessons that match the current class and key stage
        if (yourLessonJsonData && Array.isArray(yourLessonJsonData)) {
            const matchingLessons = yourLessonJsonData.filter(lesson => 
                lesson.metadata && 
                lesson.metadata.classTitle === className && 
                lesson.metadata.keyStage === keyStage
            );
            
            console.log('Matching lessons:', matchingLessons);
            
            if (matchingLessons.length > 0) {
                console.log('Setting currentLessonData to matching lessons');
                setCurrentLessonData(matchingLessons);
            } else {
                console.log('No matching lessons found, setting currentLessonData to null');
                setCurrentLessonData(null);
            }
        } else {
            console.log('Invalid JSON data format or no data available');
            setCurrentLessonData(null);
        }
    }, [className, keyStage, yourLessonJsonData]);

    const generatePresentation = async () => {
        if (!currentLessonData) {
            console.error('No lesson data available for the current class and key stage');
            return;
        }
        
        try {
            setIsGeneratingPresentation(true);
            
            // Call the Cloud Function to create the presentation
            const response = await fetch(functionUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ lesson: currentLessonData }),
            });
            
            const data = await response.json();
            
            if (data.success) {
                setPresentationUrl(data.presentationUrl);
                // Optional: Show success notification
            } else {
                console.error('Failed to generate presentation:', data.error);
                // Optional: Show error notification
            }
        } catch (error) {
            console.error('Error generating presentation:', error);
            // Optional: Show error notification
        } finally {
            setIsGeneratingPresentation(false);
        }
    };

    return (
        <MainLayout>
            <Box sx={{
                height: currentLessonData ? 'auto' : '80vh',
                p: 3,
                position: 'relative',
                display: 'flex',
                flexDirection: "column"
            }}>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton
                            onClick={() => navigate(-1)}
                            sx={{
                                color: 'inherit',
                                '&:hover': {
                                    background: 'rgba(0, 0, 0, 0.04)'
                                }
                            }}
                        >
                            <IconArrowLeft />
                        </IconButton>
                        <Typography variant={'h3'}>
                            {className ? className : ''}
                        </Typography>
                    </Stack>
                    
                    {/* Presentation Button - only show if we have lesson data for current class/key stage */}
                    {currentLessonData && (
                        <Button
                            variant="contained"
                            startIcon={<IconPresentation />}
                            onClick={generatePresentation}
                            // disabled={isGeneratingPresentation}
                            disabled={true}
                            sx={{
                                background: 'linear-gradient(90deg, #4c81f2, #a670bd)',
                                color: 'white',
                                fontFamily: 'Poppins',
                                '&:hover': {
                                    background: 'linear-gradient(90deg, #3b70e1, #9560ac)',
                                }
                            }}
                        >
                            {isGeneratingPresentation ? 'Creating Presentation...' : 'Create Presentation'}
                        </Button>
                    )}
                </Stack>

                {/* Presentation Link */}
                {presentationUrl && (
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <Button 
                            variant="outlined"
                            href={presentationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                                borderColor: '#a670bd',
                                color: '#a670bd',
                                fontFamily: 'Poppins',
                                '&:hover': {
                                    borderColor: '#9560ac',
                                    background: 'rgba(166, 112, 189, 0.04)'
                                }
                            }}
                        >
                            Open Presentation
                        </Button>
                    </Box>
                )}

                <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3, paddingBottom: 0 }}>
                    <Tabs
                        value={keyStage}
                        onChange={handleKeyStageChange}
                        sx={{
                            '& .Mui-selected': {
                                background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                            },
                            '& .MuiTabs-indicator': {
                                background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                            }
                        }}
                    >
                        <Tab label="Key Stage 1" value={1} sx={{ fontFamily: 'Poppins' }} />
                        <Tab label="Key Stage 2" value={2} sx={{ fontFamily: 'Poppins' }} />
                        <Tab label="Key Stage 3" value={3} sx={{ fontFamily: 'Poppins' }} />
                    </Tabs>
                </Box>
                {filteredModules?.length > 0
                    ?
                    filteredModules?.map((module, index) => (
                        <Box key={index} sx={{ m: 2 }}>
                            <Stack direction={'row'} spacing={2} alignItems={'center'}
                                sx={{
                                    '&:hover': {
                                        cursor: 'pointer',
                                        scale: 1.03,
                                        background: 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)',
                                        WebkitBackgroundClip: 'text',
                                        WebkitTextFillColor: 'transparent',
                                        fontWeight: 'bold'
                                    }
                                }}
                                onClick={() => navigateToPage(module)}
                            >
                                <IconBookmarks />
                                <Typography variant={'h4'}>{module.title}</Typography>
                            </Stack>

                            {/* {module.components.length > 0 ? module.components.map((component, index) => {
                            const Component = componentMapping[component.componentName];
                            return (
                                <Stack key={index} direction={'row'} spacing={2} alignItems={'center'}>
                                    <Typography variant={'h5'}>{component.title}</Typography>
                                    {Component && <Component />}
                                </Stack>
                            );
                        }) : <Typography variant={'h5'}>No resources found</Typography>} */}
                        </Box>
                    ))
                    : !currentLessonData && (
                        <Fade in={true} timeout={1000}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column' }}>
                                <Lottie animationData={animationData} loop={true} />
                                <Typography variant={'h5'}>We're creating resources for this stage, keep checking back!</Typography>
                            </Box>
                        </Fade>
                    )
                }
            </Box>
            
            {/* Only render LessonContentRenderer when we have data */}
            {console.log("currentLessonData")}
            {console.log(currentLessonData)}
            {currentLessonData && currentLessonData.length > 0 && (
                <Box sx={{ p: 3 }}>
                    <LessonContentRenderer 
                        lessonData={currentLessonData} 
                        keyStage={keyStage} 
                        className={className} 
                        setSelectedLesson={setSelectedLesson}
                    />
                </Box>
            )}
        </MainLayout>
    );
};

export default ClassPage;
//
// Teacher-Resource-Hub-29cd1852b43c44d182791ff574780bfd
// https://www.notion.so/Teacher-Resource-Hub-29cd1852b43c44d182791ff574780bfd
// https://notion-api.splitbee.io/v1/page/Teacher-Resource-Hub-29cd1852b43c44d182791ff574780bfd
