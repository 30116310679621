import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Checkbox, Divider, Fade, FormControlLabel, Stack, TextField, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {grey} from "@mui/material/colors";
import GoogleButton from "react-google-button";
import {ArrowForwardIos} from "@mui/icons-material";
import {auth, googleProvider} from "../../config/firebase";
import {signInWithEmailAndPassword, signInWithPopup} from "firebase/auth";
import {toast} from "react-toastify";
import background from "../../assets/rome-background.png";
import { useTheme } from "@mui/material/styles";
import { MiniHeader } from "../../components/header/MiniHeader";
export const LoginPage = () => {
    const [loginWithMail, setLoginWithMail] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const passwordRef = useRef(null);
    const navigate = useNavigate();
    const theme = useTheme();
    useEffect(() => {
        if (loginWithMail) {
            passwordRef.current?.focus();
        }
    }, [loginWithMail])

    async function handleLoginGoogle() {
        console.log('login google')
        try {
            await signInWithPopup(auth, googleProvider);
            navigate("/")
        } catch (err) {
            console.log(err);
        }
    }

    const loginHandle = async () => {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (err) {
            toast.error(err.message);
        }
    }

    return (
        <Box sx={{backgroundImage: `url(${background})`, backgroundSize: 'cover', minHeight:'100vh'}}>
            <MiniHeader/>

            <Stack direction={"column"} sx={{width: "100%", height: "100vh"}} justifyContent={'center'}
                   alignItems={"center"}>
                <Box sx={{
                    bgcolor: "#FFF",
                    border: 1,
                    borderRadius: 5,
                    borderColor: "divider",
                    p: 5,
                    boxShadow: 3,
                    minWidth: "300px"
                }}>
                    <Stack direction={"column"} gap={3} sx={{width: "100%", height: "100%"}} justifyContent={'center'}
                           alignItems={"center"}>
                        {/*HEADER*/}
                        <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
                            {/*TITLE*/}
                            <Typography sx={{color: theme.palette.purple.main}} variant={'h4'} textAlign={"center"}
                                        fontWeight={"bold"}>Portal Login</Typography>
                            {/*SIGN UP*/}
                            <Box>
                                <Typography sx={{color: grey[700]}} component={"span"}>Dont have account
                                    yet?</Typography>
                                <Typography variant={"body1"} component={Link} to={"/register"} sx={{
                                    pl: 1,
                                    color: theme.palette.purple.main,
                                    fontWeight: 'bold',
                                    textDecoration: "none"
                                }}>Sign up</Typography>
                            </Box>
                        </Stack>
                        {/*LOGIN WITH MAIL*/}
                        <Stack direction={"column"} gap={2} sx={{width: '100%'}}>
                            <TextField
                                type={"email"}
                                variant={"standard"}
                                required
                                fullWidth
                                label={"Email"}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder={"Enter your email address"}
                                autoComplete="email"
                                name="email"
                                id="email"
                            />
                            {
                                loginWithMail &&
                                <Fade
                                    in={loginWithMail}
                                >
                                    <TextField
                                        ref={passwordRef}
                                        autoFocus
                                        type={"password"}
                                        variant={"standard"}
                                        required
                                        fullWidth
                                        label={"Password"}
                                        value={password}
                                        onChange={e => setPassword(e.target.value)}
                                        placeholder={"Password"}
                                    />
                                </Fade>
                            }

                            <Stack direction={"row"} gap={2} justifyContent={"space-between"} alignItems={"center"}
                                   useFlexGap flexWrap={"wrap"}>
                                <FormControlLabel sx={{color: grey[700]}} control={<Checkbox defaultChecked
                                                                                             sx={{"&.Mui-checked": {color: "#43163a"}}}/>}
                                                  label="Remember me"/>
                                <Typography sx={{textDecoration: "none", color: grey[700]}} component={Link}
                                            to={"/forgot-password"}>Forgot password?</Typography>
                            </Stack>
                            {
                                loginWithMail ?
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        onClick={loginHandle}
                                        sx={{
                                            textTransform: "capitalize",
                                            bgcolor: theme.palette.purple.main,
                                            fontWeight: "bold",
                                            '&:hover': {bgcolor: theme.palette.purple.dark}
                                        }}
                                    >
                                        Log in <ArrowForwardIos sx={{fontSize: 12}} />
                                    </Button>
                                    :
                                    <Button
                                        fullWidth
                                        variant={"contained"}
                                        disabled={!email}
                                        onClick={() => setLoginWithMail(true )}
                                        sx={{
                                            textTransform: "capitalize",
                                            bgcolor: theme.palette.purple.main,
                                            fontWeight: "bold",
                                            '&:hover': {bgcolor: theme.palette.purple.dark}
                                        }}
                                    >
                                        Continue <ArrowForwardIos sx={{fontSize: 12}} />
                                    </Button>
                            }

                        </Stack>
                        {/* {
                            !loginWithMail &&
                            <Fade
                                in={!loginWithMail}
                            >
                                <Box sx={{width: "100%"}}>
                                    {!loginWithMail && <Divider sx={{color: grey[500]}}><Typography variant={'body1'}>or sign in with</Typography></Divider>}
                                </Box>
                            </Fade>
                        }
                        {
                            !loginWithMail &&
                            <Fade
                                in={!loginWithMail}
                            >
                                <Box sx={{width: "100%"}}>

                                    <GoogleButton
                                        style={{width: "100%", borderRadius: "8px"}}
                                        onClick={() => handleLoginGoogle()}
                                        type={"light"}
                                    />
                                </Box>
                            </Fade>
                        } */}

                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}