import { Button, Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import InternshipCard from '../../components/InternshipCard';

import { Context as AppContext } from "../../context/AppContext";
import { getImageUrl } from '../../utils/functions';

const ListOfInterships = ({ interns }) => {
    const navigate = useNavigate();
    const {state: {currentUser}} = useContext(AppContext);
    console.log(interns);
    
    if (!interns || interns?.length === 0) {
        return (
            <Stack gap={2} alignItems="center">
                <Typography variant={'h5'} sx={{ mt: 5 }}>
                    No internships in progress at the moment
                </Typography>
                <Typography variant={'body1'} color="text.secondary">
                    Check the Applicants tab to accept students and start mentoring
                </Typography>
                <Button 
                    variant="contained" 
                    onClick={() => navigate('/applicants')}
                    sx={{ mt: 2 }}
                    className="rainbow-button"
                >
                    View Applicants
                </Button>
            </Stack>
        );
    }
  
    return (
        <Box>
            <Typography variant={'h4'} sx={{mt: 5, mb: 2}}>Internships in progress:</Typography>
            <Grid container spacing={2} justifyContent="flex-start">
                {interns?.map((intern, index) => {
                    return (
                        <Grid 
                            item 
                            xs={12} 
                            lg={6} 
                            xl={4} 
                            sx={{ 
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                            key={intern.uid}
                        >
                            <InternshipCard 
                                intern={intern}
                                index={index}
                                companyImage={getImageUrl(currentUser.company)}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </Box>
    );
};

export default ListOfInterships;
