import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Grid, IconButton, List, ListItem, ListItemText, Paper, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Context as AppContext } from "../../context/AppContext";
import { MainLayout } from "../../layouts/main-layout/MainLayout";
import { capitalizeFirstWord } from '../../utils/functions';
import './mentorHomepage.css';

const Internship = ({ isMentor = true }) => {
    const { state: { currentUser, interns, internships }, updateInternship, getStudents, getInternships } = useContext(AppContext);
    const { id } = useParams()
    const [internship, setInternship] = useState(null)
    const [student, setStudent] = useState(null)
    const [title, setTitle] = useState(internship?.title || '');
    const [newTask, setNewTask] = useState('');
    const [newGoal, setNewGoal] = useState('');
    const [localTitle, setLocalTitle] = useState('');
    const navigate = useNavigate()
    useEffect(() => {
       if(!internships || internships?.length === 0){
        navigate('/mentor')
       }
    }, [currentUser])

    useEffect(() => {
        if (internships?.length > 0) {
            const internship = internships.find(internship => internship.uid === id)
            setInternship(internship)
            setLocalTitle(internship?.title || '')
            const intern = interns.find(intern => intern.uid === internship.studentUid)
            setStudent(intern)
        }
    }, [internships])

    useEffect(() => {
        console.log(internship)
        if (internship) {
            console.log('updating internship')
            console.log(internship)
            updateInternship(internship)
        }
    }, [internship])

    const handleTitleChange = (event) => {
        setLocalTitle(event.target.value);
    };

    const handleTitleBlur = () => {
        if (localTitle !== internship?.title) {
            setInternship(prev => ({
                ...prev,
                title: localTitle
            }));
        }
    };

    const handleAddTask = () => {
        if (!newTask.trim()) return;

        const newTaskObj = {
            id: Date.now(), // temporary ID, replace with proper ID generation
            description: newTask,
            completed: false
        };

        setInternship(prev => ({
            ...prev,
            tasks: [...(prev.tasks || []), newTaskObj]
        }));

        setNewTask('');
        // TODO: Implement backend update
    };

    const handleDeleteTask = (taskId) => {
        setInternship(prev => ({
            ...prev,
            tasks: prev.tasks.filter(task => task.id !== taskId)
        }));
        // TODO: Implement backend update
    };

    const handleAddGoal = () => {
        if (!newGoal.trim()) return;

        const newGoalObj = {
            id: Date.now(),
            description: newGoal,
            completed: false
        };

        setInternship(prev => ({
            ...prev,
            goals: [...(prev.goals || []), newGoalObj]
        }));

        setNewGoal('');
        // TODO: Implement backend update
    };

    const handleDeleteGoal = (goalId) => {
        setInternship(prev => ({
            ...prev,
            goals: prev.goals.filter(goal => goal.id !== goalId)
        }));
        // TODO: Implement backend update
    };

    return (
        <MainLayout>
            <Stack gap={3} sx={{ p: 3, pb: 1 }}>
                <Box>
                    <Typography variant={'h2'}>Welcome,
                        <span className={'rainbow-text'}> {capitalizeFirstWord(currentUser?.name)} </span>
                        and
                        <span className={'rainbow-text'}> {capitalizeFirstWord(student?.name)} </span>
                    </Typography>

                    <Typography variant={'body1'}>
                        {internship?.startDate ? new Date(internship.startDate).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        }) : ''} - {internship?.endDate ? new Date(internship.endDate).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                            year: 'numeric'
                        }) : ''}
                    </Typography>
                </Box>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h4" sx={{ mt: 3 }}>Internship Title</Typography>
                    <TextField
                        placeholder="Internship Title"
                        value={localTitle}
                        onChange={handleTitleChange}
                        onBlur={handleTitleBlur}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            notched: false,
                        }}
                        InputLabelProps={{
                            shrink: false,
                        }}
                    />
                </Stack>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>Goals</Typography>
                        <Stack direction="column" spacing={2}>
                            <TextField
                                placeholder="New Goal"
                                value={newGoal}
                                onChange={(e) => setNewGoal(e.target.value)}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    notched: false,
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddGoal();
                                    }
                                }}
                            />
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={handleAddGoal}
                                sx={{ width: '150px' }}
                                className='rainbow-button'
                            >
                                Add Goal
                            </Button>
                        </Stack>

                        {/* Goals List */}
                        <List sx={{ width: '100%', mt: 2 }}>
                            {internship?.goals?.map((goal, index) => (
                                <ListItem
                                    sx={{ mt: 1 }}
                                    component={Paper}
                                    key={goal.id}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleDeleteGoal(goal.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={`${index + 1}. ${goal.description}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h4" sx={{ mt: 3, mb: 1 }}>Tasks</Typography>
                        <Stack direction="column" spacing={2}>
                            <TextField
                                placeholder="New Task"
                                value={newTask}
                                onChange={(e) => setNewTask(e.target.value)}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    notched: false,
                                }}
                                InputLabelProps={{
                                    shrink: false,
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddTask();
                                    }
                                }}
                            />
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={handleAddTask}
                                sx={{ width: '150px' }}
                                className='rainbow-button'
                            >
                                Add Task
                            </Button>
                        </Stack>

                        {/* Tasks List */}
                        <List sx={{ width: '100%', mt: 2 }}>
                            {internship?.tasks?.map((task, index) => (
                                <ListItem
                                    sx={{ mt: 1 }}
                                    component={Paper}
                                    key={task.id}
                                    secondaryAction={
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => handleDeleteTask(task.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={`${index + 1}. ${task.description}`}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                </Grid>
            </Stack>
        </MainLayout>
    );
};

export default Internship;
