import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper
} from '@mui/material';

const AssessmentToolkitExploringHabitatsWithAi = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        Assessment Toolkit
                    </Typography>
                    <Typography variant="body1" paragraph>
                        This Assessment Toolkit ensures that teachers can effectively evaluate student understanding, engagement with AI, and creativity while maintaining alignment with the lesson objectives.
                    </Typography>
                </Box>

                {/* Observation-Based Rubric */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Observation-Based Rubric
                    </Typography>
                    <TableContainer component={Paper} sx={{ mb: 4 }}>
                        <Table aria-label="observation rubric">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Criteria</TableCell>
                                    <TableCell align="center">1 - Needs Improvement</TableCell>
                                    <TableCell align="center">3 - Satisfactory</TableCell>
                                    <TableCell align="center">5 - Excellent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Understanding Habitats</TableCell>
                                    <TableCell align="center">Struggles to identify features or adaptations of habitats.</TableCell>
                                    <TableCell align="center">Identifies some features but lacks depth.</TableCell>
                                    <TableCell align="center">Explains features and adaptations clearly with examples.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Engagement with AI</TableCell>
                                    <TableCell align="center">Minimal engagement with AI outputs.</TableCell>
                                    <TableCell align="center">Participates but relies heavily on teacher guidance.</TableCell>
                                    <TableCell align="center">Actively engages, critiques AI responses, and offers insights.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Collaboration</TableCell>
                                    <TableCell align="center">Rarely contributes to group discussions or tasks.</TableCell>
                                    <TableCell align="center">Occasionally contributes ideas or listens.</TableCell>
                                    <TableCell align="center">Actively participates, shares ideas, and works well with peers.</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Creative Problem-Solving</TableCell>
                                    <TableCell align="center">Struggles to propose ideas for habitat adaptation or protection.</TableCell>
                                    <TableCell align="center">Suggests basic solutions with minimal elaboration.</TableCell>
                                    <TableCell align="center">Proposes creative, well-thought-out solutions backed by reasoning.</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Self-Assessment Checklist */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Self-Assessment Checklist
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Habitat Knowledge"
                                secondary="I can explain at least one adaptation that helps an animal survive in its habitat."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Habitat Knowledge"
                                secondary="I understand why habitats need to be protected."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="AI Engagement"
                                secondary="I used AI to explore new ideas about habitats."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="AI Engagement"
                                secondary="I can explain one thing I learned from the AI’s response."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Group Work"
                                secondary="I shared my ideas with my group."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimelineIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Group Work"
                                secondary="I listened to others and built on their suggestions."
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default AssessmentToolkitExploringHabitatsWithAi;
