import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineIcon from '@mui/icons-material/Timeline';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';

const AiPromptsExploringHabitatsWithAi = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Introduction */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" gutterBottom>
                        The Teacher’s Role with AI
                    </Typography>
                    <Typography variant="body1" paragraph>
                        AI becomes a collaborative thinking partner. The teacher uses AI to spark inquiry, simulate real-world challenges, and scaffold deeper learning. AI outputs are not answers but provocations, inspiring students to:
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Challenge assumptions." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Explore 'what if' scenarios." />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AutoAwesomeIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Develop interdisciplinary solutions." />
                        </ListItem>
                    </List>
                    <Typography variant="body1" paragraph>
                        Students don’t passively consume AI outputs—they analyze, critique, and build on them.
                    </Typography>
                </Box>

                {/* Impactful AI Integration Ideas */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Impactful AI Integration Ideas
                    </Typography>

                    {/* Generate "What If" Scenarios */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            1. Generate “What If” Scenarios
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Goal: Help students explore how habitats change and how animals adapt.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Example Prompt:
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ fontStyle: 'italic' }}>
                            “Imagine the rainforest became a desert. How would animals and plants need to adapt to survive?”
                        </Typography>
                        <Typography variant="body1" paragraph>
                            What to Do in Class:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Share the AI-generated scenario with students." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Ask: 'Do you agree with these ideas? What would you add or change?'" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CircleIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Follow-up: 'Which of these adaptations could happen in real life?'" />
                            </ListItem>
                        </List>
                    </Box>

                    {/* AI-Powered Problem-Solving */}
                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            2. AI-Powered Problem-Solving
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Goal: Encourage creative thinking about how humans can protect habitats.
                        </Typography>
                        <Typography variant="body2" paragraph>
                            Example Prompt:
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ fontStyle: 'italic' }}>
                            “What technologies could humans create to help polar bears if the Arctic ice melts?”
                        </Typography>
                        <Typography variant="body1" paragraph>
                            What to Do in Class:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <TimelineIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Present AI’s suggestions and let students rank them: 'Which idea is the most realistic? Why?'" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <TimelineIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Challenge students to improve or invent their own solutions." />
                            </ListItem>
                        </List>
                    </Box>

                    {/* Use the AIDEN Curriculum Designer */}
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            TIP: Use the AIDEN Curriculum Designer
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Teachers can use the AIDEN Curriculum Designer to:
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AutoAwesomeIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Generate new exercises like these, tailored to your lesson goals." />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AutoAwesomeIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary="Get inspiration for more AI-powered activities that fit your students’ needs." />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AiPromptsExploringHabitatsWithAi;
