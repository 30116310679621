import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, } from "@mui/material";
import { grey } from "@mui/material/colors";
import { IconBook, IconBulb, IconHeart, IconHome, IconLogout, IconMessage, IconMicrophone, IconSchool, IconUsers, } from "@tabler/icons-react";
import { signOut } from "firebase/auth";
import React, { useContext, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import loadingGif from "../../assets/ai-loading1.webm";
import { auth } from "../../config/firebase";
import { Context as AppContext } from "../../context/AppContext";
import { enums } from "../../utils/enums";
import { Header, ShareThought, ToggleMenu } from "./Header";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";

export const MainLayout = ({children, onClose}) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const {state: {currentUser}} = useContext(AppContext);
    
    if (!currentUser) {
        return <LoadingScreen/>
    }
    
    return (
        <Box
            sx={{
                bgcolor: '#FFFFFF',
                width: "100%",
                height: '100vh',
                display: "flex",
                flexDirection: "column",
                overflow: 'hidden'
            }}
        >
            <Stack
                sx={{
                    bgcolor: "#FFFFFF",
                    boxShadow: {xs: 0, md: 8},
                    flex: 1,
                    height: '100%',
                    overflow: 'hidden'
                }}
            >
                <Header onToggle={() => setOpenDrawer(!openDrawer)}/>
                <Stack direction={"row"} sx={{flex: 1, height: '100%', overflow: 'hidden'}}>
                    <SideBar isOpen={openDrawer} onToggle={() => setOpenDrawer(!openDrawer)} onClose={onClose}/>
                    <Box
                        sx={{
                            flex: 1,
                            bgcolor: '#FFFFFF',
                            overflow: 'auto',
                            height: '100%'
                        }}
                    >
                        {children}
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

export const SideBar = ({isOpen, onToggle, onClose}) => {

    return (
        <>
            {
                isOpen &&
                <Drawer
                    anchor={"left"}
                    sx={{
                        display: {xs: "block", md: "none"},
                        height: '90vh',
                        maxHeight: '90vh',

                    }}
                    PaperProps={{sx: {width: "100%", maxWidth: "320px"}}}
                    open={isOpen}
                    onClose={onToggle}
                >
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={1} sx={{p: 1}}>
                        <ToggleMenu onToggle={onToggle}/>
                    </Stack>
                    <SideBarItems onClose={onClose}/>
                </Drawer>
            }
            <Box
                sx={{
                    display: {xs: "none", md: "block"},
                    width: "100%",
                    height: '100%',
                    maxWidth: "280px",
                    borderRight: 1,
                    borderColor: "divider",
                    position: "sticky",
                    alignSelf: "flex-start",
                    top: 0,
                    background: 'radial-gradient(circle farthest-corner at 90% 80%, rgba(246, 247, 248, 0.1), #EEE5F3, #F3E5F1, rgba(241, 233, 244, 0.1))'
                }}
                component={"aside"}
            >
                <SideBarItems/>
            </Box>
        </>

    )
}

const SideBarItems = ({onClose}) => {
    const {state: {currentUser}} = useContext(AppContext);
    const iconColor = '#613896';
    const location = useLocation();
   
    const isActivePath = (path) => {
        return location.pathname.startsWith(path);
    };

    return (
        <>
            <Box sx={{display: {xs: "block", md: "none"}, mx: 2}}>
                <ShareThought fullWidth/>
            </Box>
            <List sx={{height: '80vh', maxHeight: '80vh'}}>
                {[enums.ADMIN, enums.TEACHER].includes(currentUser?.role)
                    &&
                    <>
                        <ListItem>
                            <ListItemText
                                primary={"For Teachers"}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: 13,
                                        color: grey[500],
                                        fontWeight: 600,
                                        textTransform: "uppercase"
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItemButton component={NavLink} className="nav-link" to={"/resource-hub"}>
                            <ListItemIcon sx={{color: isActivePath('/resource-hub') && iconColor}}><IconHome/></ListItemIcon>
                            <ListItemText 
                                primary={"Resource hub"}
                                primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/resource-hub') ? 700 : 400 }
                                }}
                            />
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/class-gpt"}>
                            <ListItemIcon sx={{color: isActivePath('/class-gpt') && iconColor}}><IconSchool/></ListItemIcon>
                            <ListItemText 
                                primary={"In-Class GPT"}
                                primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/class-gpt') ? 700 : 400 }
                                }}
                            />
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/master-prompts"}>
                            <ListItemIcon sx={{color: isActivePath('/master-prompts') && iconColor}}>
                               <IconBook />
                            </ListItemIcon>
                                <ListItemText primary={"Master Prompts"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/master-prompts') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/ai-designer"}>
                            <ListItemIcon>
                                <video style={{width: '30px'}}
                                       autoPlay
                                       loop
                                       muted src={loadingGif}/>
                            </ListItemIcon>
                            <ListItemText primary={"AI Curriculum Designer"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/ai-designer') ? 700 : 400 }
                                }}/>
                        </ListItemButton> 
                    </>
                }

                {[enums.MENTOR, enums.ADMIN].includes(currentUser?.role)
                    &&
                    <>
                        <ListItem>
                            <ListItemText
                                primary={"Internships"}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: 13,
                                        color: grey[500],
                                        fontWeight: 600,
                                        textTransform: "uppercase"
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItemButton component={NavLink} className="nav-link" to={"/mentor"}>
                            <ListItemIcon sx={{color: isActivePath('/mentor') && iconColor }}><IconSchool/></ListItemIcon>
                            <ListItemText primary={"Mentor Dashboard"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/mentor') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/applicants"}>
                            <ListItemIcon sx={{color: isActivePath('/applicants') && iconColor }}><IconUsers/></ListItemIcon>
                            <ListItemText primary={"Applicants"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/applicants') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/ai-internship-designer"}>
                            <ListItemIcon>
                                <video style={{width: '30px'}}
                                       autoPlay
                                       loop
                                       muted src={loadingGif}/>
                            </ListItemIcon>
                            <ListItemText primary={"AI Internship Designer"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/ai-internship-designer') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                    </>}
                {[enums.INTERN, enums.ADMIN].includes(currentUser?.role) &&
                    <>
                        <ListItemButton component={NavLink} className="nav-link" to={"/intern"}>
                            <ListItemIcon sx={{color: '#613896'}}><IconSchool/></ListItemIcon>
                            <ListItemText primary={"Intern Dashboard"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/intern') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/mentors"}>
                            <ListItemIcon sx={{color: '#613896'}}><IconMicrophone/></ListItemIcon>
                            <ListItemText primary={"Mentors"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/mentors') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                    </>
                }
                {[enums.ADMIN].includes(currentUser?.role)
                    &&
                    <>
                        <ListItem>
                            <ListItemText
                                primary={"Personal navigator"}
                                primaryTypographyProps={{
                                    sx: {
                                        fontSize: 13,
                                        color: grey[500],
                                        fontWeight: 600,
                                        textTransform: "uppercase"
                                    }
                                }}
                            />
                        </ListItem>
                        <ListItemButton component={NavLink} className="nav-link" to={"/learning"}>
                            <ListItemIcon><IconBook/></ListItemIcon>
                            <ListItemText primary={"Personalized Learning"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/learning') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/my-ideas"}>
                            <ListItemIcon><IconBulb/></ListItemIcon>
                            <ListItemText primary={"Your ideas"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/my-ideas') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/conversations"}>
                            <ListItemIcon><IconMessage/></ListItemIcon>
                            <ListItemText primary={"Conversations"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/conversations') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                        <ListItemButton component={NavLink} className="nav-link" to={"/my-likes"}>
                            <ListItemIcon><IconHeart/></ListItemIcon>
                            <ListItemText primary={"Your likes & votes"} primaryTypographyProps={{
                                    sx: { fontWeight: isActivePath('/my-likes') ? 700 : 400 }
                                }}/>
                        </ListItemButton>
                    </>}
            </List>

            <List sx={{display: {xs: "block", md: "none"}}}>
                <ListItem>
                    <ListItemText
                        primary={"Profile"}
                        primaryTypographyProps={{
                            sx: {
                                fontSize: 13,
                                color: grey[500],
                                fontWeight: 600,
                                textTransform: "uppercase"
                            }
                        }}
                    />
                </ListItem>
                {/*<ListItemButton component={NavLink} className="nav-link" to={"/notifications"}>*/}
                {/*    <ListItemIcon><IconBell/></ListItemIcon>*/}
                {/*    <ListItemText primary={"Notifications"} primaryTypographyProps={{sx: {fontColor: grey[500]}}}/>*/}
                {/*</ListItemButton>*/}
                <ListItemButton className="nav-link" onClick={async () => await signOut(auth)}>
                    <ListItemIcon><IconLogout/></ListItemIcon>
                    <ListItemText primary={"Logout"} primaryTypographyProps={{sx: {fontColor: grey[500]}}}/>
                </ListItemButton>
            </List>
        </>
    )
}
