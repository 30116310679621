import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import styled from "styled-components";
import { grades, classes } from "../../utils/enums";
const ClassSelection = ({ selectedClass, setSelectedClass, selectedGrade, setSelectedGrade }) => {
    const AppleFormControl = styled(FormControl)({
        minWidth: {
            xs: '100px',
            sm: '200px'
        },
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        '& .MuiInputBase-root': {
            borderRadius: '12px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#d1d1d1',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#a1a1a1',
        },
        '& .MuiInputLabel-root': {
            fontFamily: 'San Francisco, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            color: '#555',
        },
        '& .MuiSelect-select': {
            fontFamily: 'San Francisco, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
            padding: '10px 14px',
        },
    });
    return (
        <Box sx={{
            mt: 2, mb: 2, pr:2, display: 'flex', alignItems: 'center', alignContent: 'center',
            justifyItems: 'center', justifyContent: 'center'
        }}>
            <Stack direction={{ xs: 'row', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                spacing={2}
                sx={{ xs: { ml: 2 } }}
            >
                <AppleFormControl sx={{ minWidth: 100 }}>
                    <InputLabel id="grade-select-label">Grade</InputLabel>
                    <Select
                        labelId="grade-select-label"
                        value={selectedGrade}
                        onChange={(e) => setSelectedGrade(e.target.value)}
                        label="Grade"
                    >
                        {grades.map((grade) => (
                            <MenuItem key={grade} value={grade}>{grade}</MenuItem>
                        ))}
                    </Select>
                </AppleFormControl>
                <AppleFormControl variant={'outlined'} sx={{ minWidth: 100 }}>
                    <InputLabel id="class-select-label">Subject</InputLabel>
                    <Select
                        labelId="class-select-label"
                        value={selectedClass}
                        onChange={(e) => setSelectedClass(e.target.value)}
                        label="Class"
                    >
                        {classes.map((cls) => (
                            <MenuItem key={cls} value={cls.name}>{cls.name}</MenuItem>
                        ))}
                    </Select>
                </AppleFormControl>
            </Stack>
        </Box>
    );
};

export default ClassSelection;

