import { Box, Stack, Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import { Context as AppContext } from '../../context/AppContext';
import { MainLayout } from '../../layouts/main-layout/MainLayout';
import AiChat from '../ai-designer/AiChat';
const ClassGptChat = () => {
    const { state: { GPTS }, updateGPT, deleteGPT } = useContext(AppContext)
    const { uid } = useParams();
    const [gpt, setGpt] = useState(null)

    const navigate = useNavigate();
    useEffect(() => {
        const gpt = GPTS.find(gpt => gpt.uid === uid)
        if (gpt) {
            setGpt(gpt)
        } else {
            navigate('/class-gpt')
        }
    }, [uid, GPTS])

    if (!gpt) {
        return <div>Loading...</div>
    }

    return (
        <MainLayout>
            <Stack sx={{ height: '98%' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <PageHeader
                        title={`In-Class GPT - `}
                        rainbowText={gpt?.title}
                       
                    />
                </Box>

                <AiChat
                    initialMessages={gpt?.messages}
                    showSelectors={false}
                    gptUid={gpt?.uid}
                />
            </Stack>
        </MainLayout>
    );
};

export default ClassGptChat; 