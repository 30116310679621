import React, {useContext, useEffect, useState} from 'react';
import {Box, IconButton, Stack, TextField, Tooltip} from "@mui/material";
import ChatWindow from "./ChatWindow";
import loadingGif from "../../assets/ai-loading.webm";
import SendIcon from "@mui/icons-material/Send";
import {Context as AppContext} from "../../context/AppContext";
import {toast} from "react-toastify";
import {httpsCallable} from "firebase/functions";
import {functions, db} from "../../config/firebase";
import {addDoc, collection, doc, deleteDoc, updateDoc, serverTimestamp, query, where, getDocs, orderBy, limit} from "firebase/firestore";
import aidenSmall from "../../assets/small-logo.png";
import { keyframes } from '@mui/system';
import PropTypes from 'prop-types';
import { IconPencil } from '@tabler/icons-react';
import {IconMessagePlus} from '@tabler/icons-react';

// TODO: Consider moving animations to a shared styles file
const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const AiChat = ({
    title,                    // Page title
    initialMessages = [],     // Initial messages to populate the chat
    showSelectors = false,    // Whether to show class/grade selectors
    SelectionComponent,       // Custom selection component (optional)
    customPlaceholder,        // Custom placeholder for text input
    firebaseFunctionName = "getChatCompletionC",  // Name of Firebase function to call
    gptUid = null,
}) => {
    const {state: {messages, currentUser}, updateMessages} = useContext(AppContext)
    const getChatCompletion = httpsCallable(functions, firebaseFunctionName);
    const [isAnswering, setIsAnswering] = useState(false);
    const [userQuery, setUserQuery] = useState('');
    const [chatGptUserQuery, setChatGptUserQuery] = useState(null);
    const [getResponseFromChat, setGetResponseFromChat] = useState(0);
    const [conversationId, setConversationId] = useState(null);

    // TODO: Consider if these should be moved to SelectionComponent
    const [selectedOptions, setSelectedOptions] = useState({});

    useEffect(() => {
        // Load existing conversation or create a new one
        if (currentUser) {
            loadOrCreateConversation();
        } else {
            // Initialize with provided messages if no user
            updateMessages(initialMessages);
        }
    }, [currentUser]);

    useEffect(() => {
        if (getResponseFromChat > 0) {
            sendChatGptPrompt();
        }
    }, [getResponseFromChat]);

    useEffect(() => {
        if (!chatGptUserQuery) return;

        messages.push(chatGptUserQuery);
        updateMessages([...messages])
        setUserQuery('');
        getMessages();

    }, [chatGptUserQuery]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'Enter') {
                setGetResponseFromChat(prev => prev + 1);
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const loadOrCreateConversation = async () => {
        try {
            if (!currentUser) {
                updateMessages(initialMessages);
                return;
            }

            // Check if user has an active conversation
            const conversationsRef = collection(db, "conversations");
            const q = query(
                conversationsRef, 
                where("userId", "==", currentUser.uid),
                where("isActive", "==", true),
                where("gptUid", "==", gptUid),
                orderBy("createdAt", "desc"),
                limit(1)
            );
            
            const querySnapshot = await getDocs(q);
            
            if (!querySnapshot.empty) {
                // Use existing active conversation
                const conversationDoc = querySnapshot.docs[0];
                setConversationId(conversationDoc.id);
                
                // Load messages from conversation
                const conversationData = conversationDoc.data();
                if (conversationData.messages && conversationData.messages.length > 0) {
                    updateMessages(conversationData.messages);
                } else {
                    updateMessages(initialMessages);
                }
            } else {
                // Create new conversation
                createNewConversation();
            }
        } catch (error) {
            console.error("Error loading conversation:", error);
            toast.error("Failed to load conversation history");
            // Fall back to initial messages
            updateMessages(initialMessages);
        }
    };

    const createNewConversation = async () => {
        try {
            if (!currentUser) return;

            const newConversation = {
                userId: currentUser.uid,
                userEmail: currentUser.email || "",
                createdAt: serverTimestamp(),
                updatedAt: serverTimestamp(),
                isActive: true,
                messages: initialMessages,
                gptUid: gptUid
            };
            
            // Add any selected options if available
            if (Object.keys(selectedOptions).length > 0) {
                newConversation.selectedOptions = selectedOptions;
            }
            
            const docRef = await addDoc(collection(db, "conversations"), newConversation);
            setConversationId(docRef.id);
            updateMessages(initialMessages);
        } catch (error) {
            console.error("Error creating new conversation:", error);
            toast.error("Failed to create new conversation");
        }
    };

    const clearConversation = async () => {
        try {
            // Mark current conversation as inactive
            if (conversationId) {
                await updateDoc(doc(db, "conversations", conversationId), {
                    isActive: false,
                    updatedAt: serverTimestamp()
                });
            }
            
            // Create a new conversation
            createNewConversation();
            
            toast.success("Started a new conversation");
        } catch (error) {
            console.error("Error clearing conversation:", error);
            toast.error("Failed to clear conversation");
        }
    };

    // TODO: Consider making error messages configurable
    async function getMessages() {
        try {
            setIsAnswering(true);
            const response = await getChatCompletion({messages: messages});

            if (response.data) {
                const updatedMessages = [...messages, response.data.message];
                updateMessages(updatedMessages);
                setChatGptUserQuery(null);
                
                // Update conversation in Firestore
                if (conversationId && currentUser) {
                    await updateDoc(doc(db, "conversations", conversationId), {
                        messages: updatedMessages,
                        updatedAt: serverTimestamp(),
                        // Update selected options if they've changed
                        ...(Object.keys(selectedOptions).length > 0 && { selectedOptions })
                    });
                }
                
                setIsAnswering(false);
            } else {
                toast.error('Something went wrong, please try again!');
                setIsAnswering(false);
            }
        } catch (error) {
            console.error("Error fetching chat completion:", error);
            toast.error('An error occurred, please try again!');
            setIsAnswering(false);
        }
    }

    function sendChatGptPrompt() {
        const userMessage = {
            "role": "user",
            "content": userQuery
        };
        
        setChatGptUserQuery(userMessage);
        
        // Update conversation in Firestore with the user message
        if (conversationId && currentUser) {
            const updatedMessages = [...messages, userMessage];
            updateDoc(doc(db, "conversations", conversationId), {
                messages: updatedMessages,
                updatedAt: serverTimestamp(),
                // Update selected options if available
                ...(Object.keys(selectedOptions).length > 0 && { selectedOptions })
            }).catch(error => {
                console.error("Error updating conversation with user message:", error);
            });
        }
    }

    // TODO: Consider making styles configurable via props
    return (
            <Box sx={{
                height: 'calc(100% - 60px)',
                 overflow: 'hidden',
                 p: 3,
                 position: 'relative',
                 display: 'flex',
                 flexDirection: "column",
                
            }}>
                <Stack 
                    direction={{ xs: 'row'}} 
                    alignItems={{ xs: 'flex-start', sm: 'flex-start' }}
                    justifyContent={{ xs: 'flex-start', sm: 'space-between' }}
                    spacing={2}
                >
                   
                   
                    <Stack direction="row" spacing={2} alignItems="center">
                        {showSelectors && SelectionComponent && (
                            <SelectionComponent 
                                selectedOptions={selectedOptions}
                                setSelectedOptions={setSelectedOptions}
                            />
                        )}
                       
                    </Stack>
                </Stack>

                <ChatWindow />
                
                <Stack>
                    {isAnswering && (
                        <img 
                            src={aidenSmall} 
                            alt="AI Assistant" 
                            style={{
                                width: '20px',
                                animation: `${spin} 2s linear infinite`
                            }}
                        />
                    )}
                    <Stack 
                        direction={'row'} 
                        gap={2} 
                        alignItems={'center'} 
                        justifyContent={'center'}
                        sx={{position: 'relative', bottom: 10, width: '90%', paddingLeft: "15px"}}
                    >
                        <Tooltip title="New Conversation">
                            <IconButton
                                variant="outlined"
                                color="primary"
                                onClick={clearConversation}
                            >
                                <IconMessagePlus />
                            </IconButton>
                        </Tooltip>
                        <TextField
                            placeholder={customPlaceholder || "Start typing..."}
                            multiline 
                            rows={3} 
                            sx={{flex: 1, backgroundColor: 'white'}}
                            value={userQuery} 
                            onChange={e => setUserQuery(e.target.value)}
                        />
                        <Box sx={{textAlign: "right"}}>
                            {isAnswering ? (
                                <video style={{width: '50px'}} autoPlay loop src={loadingGif}/>
                            ) : (
                                <IconButton 
                                    color="primary" 
                                    variant={"outlined"} 
                                    sx={{width: "50px", height: "50px"}}
                                    aria-label="send"
                                    onClick={() => setGetResponseFromChat(prev => prev + 1)}
                                >
                                    <SendIcon/>
                                </IconButton>
                            )}
                        </Box>
                    </Stack>
                </Stack>
            </Box>
    );
};

AiChat.propTypes = {
    // Required props
    title: PropTypes.string,
    gptUid: PropTypes.string,
    // Optional props with defaults
    initialMessages: PropTypes.arrayOf(PropTypes.shape({
        role: PropTypes.oneOf(['user', 'assistant', 'system']).isRequired,
        content: PropTypes.string.isRequired
    })),
    showSelectors: PropTypes.bool,
    SelectionComponent: PropTypes.elementType,
    customPlaceholder: PropTypes.string,
    firebaseFunctionName: PropTypes.string
};

export default AiChat; 