import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import GroupIcon from '@mui/icons-material/Group';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuizIcon from '@mui/icons-material/Quiz';
import SchoolIcon from '@mui/icons-material/School';
import TimerIcon from '@mui/icons-material/Timer';
import TargetIcon from '@mui/icons-material/TrackChanges';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";

const LessonPlanObjectivesExploringHabitatsWithAi = () => {

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {/* Main Title and National Curriculum Alignment */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h3" color="primary" gutterBottom>
                        Lesson Plan: AI and Habitats
                    </Typography>

                    <Typography variant="h4" color="primary" sx={{ mt: 4, mb: 2 }}>
                        National Curriculum Alignment
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Identify how animals and plants are adapted to suit their environment in different ways and that adaptation may lead to evolution."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Recognize that environments can change and that this can sometimes pose dangers to living things."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Use evidence to support findings in scientific inquiry."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Session Objectives */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Session Objectives
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <TargetIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Explore Habitats and Adaptations"
                                secondary="Students will study how organisms survive in specific habitats and why those adaptations matter."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <SchoolIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Simulate Environmental Changes Using AI"
                                secondary="Use AI to model hypothetical scenarios that impact habitats."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <TimerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{ variant: 'h6' }}
                                primary="Visualize Habitats"
                                secondary="Students create habitat infographics using Canva to illustrate adaptations."
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Learning Lab Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 1: Learning Lab
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Introduction to Habitats and Adaptations (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher introduces the concept of habitats and adaptations using visuals from Canva."
                                    secondary="Examples: A desert habitat with cacti and camels. A rainforest with monkeys and orchids."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <MenuBookIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Class discussion:"
                                    secondary="'What makes this animal/plant suited to its habitat?' and 'What would happen if it moved to a different habitat?'"
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            AI-Driven Exploration: "What If" Scenarios (15 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Teacher introduces GPT as a tool to simulate environmental changes."
                                    secondary="Class brainstorms questions about habitats like 'What would happen if the rainforest became a desert?' or 'How would fish survive if their lake dried up?'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <GroupIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="AI generates a response, which the teacher displays for class discussion."
                                    secondary="Discuss: 'What surprised you about the AI’s answer?' and 'What might the AI have missed?'"
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Practice Pad Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Part 2: Practice Pad
                    </Typography>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Habitat Infographics (20 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Students work in pairs to create an infographic about a chosen habitat using Canva."
                                    secondary="Include: Key features of the habitat, examples of animals/plants adapted to it, and environmental threats (e.g., deforestation, pollution)."
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <AssignmentIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Presentation:"
                                    secondary="Each pair presents their infographic, explaining the significance of adaptations."
                                />
                            </ListItem>
                        </List>
                    </Box>

                    <Box sx={{ mb: 4 }}>
                        <Typography variant="h5" color="primary" gutterBottom>
                            Class Reflection: Adapting to Change (10 min)
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Students reflect on how they might adapt if their environment changed, using AI to simulate potential adaptations."
                                    secondary="Example prompt: 'If humans lived in a desert, how might they adapt to survive?'"
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <QuizIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Class discussion:"
                                    secondary="'What did AI suggest that was interesting?' and 'What would you add or change?'"
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>

                {/* Resources Section */}
                <Box sx={{ mb: 6 }}>
                    <Typography variant="h4" color="primary" gutterBottom>
                        Resources Required
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Laptop or Tablet"
                                secondary="Each student or pair should have access to a device with internet for AI-based brainstorming and storytelling."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <BuildIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Whiteboard and Markers"
                                secondary="For brainstorming and displaying examples."
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Habitat Images or Cards" />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <AssignmentIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary="Drawing Materials for Reflections" />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default LessonPlanObjectivesExploringHabitatsWithAi;
