import LightbulbIcon from '@mui/icons-material/Lightbulb';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import PeopleIcon from '@mui/icons-material/People';

const MetacognitiveExerciseExploringHabitatsWithAi = () => {
    return (
        <>
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Metacognitive Toolkit: Habitat Rescue Mission
                </Typography>

                {/* Purpose Section */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Purpose
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Students will reflect on the impact of environmental changes on habitats and adaptations by collaborating on creative problem-solving tasks. This activity combines critical thinking, teamwork, and creative reflection.
                    </Typography>
                </Box>

                {/* Instructions for Teachers */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Instructions for Teachers
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Divide students into small groups (3-4 students each)."
                                secondary="Provide each group with a scenario generated by AI (GPT)."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Use prompts like:"
                                secondary="'Imagine the rainforest is turning into a desert. What adaptations would animals need to survive?' or 'What happens to fish if their lake dries up, and how could humans help them adapt?'"
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Print or display these scenarios for the groups."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                    </List>
                </Box>

                {/* Activity Structure */}
                <Box sx={{ mt: 4, mb: 6 }}>
                    <Typography variant="h5" color="primary" gutterBottom>
                        Activity Structure
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <LightbulbIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Step 1: Think (5 min)"
                                secondary="Students individually reflect on the AI-generated scenario and jot down their ideas to 'rescue' the habitat."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Step 2: Pair (5 min)"
                                secondary="Students pair up within their group and share their ideas. Together, they refine their solutions, combining creativity and practicality."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <QuestionAnswerIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Teacher circulates to provide guidance or pose thought-provoking questions:"
                                secondary="'What might be the biggest challenge for animals in this situation?' and 'How would your solution affect other parts of the environment?'"
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <PeopleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary="Step 3: Share (10 min)"
                                secondary="Groups present their 'Rescue Mission' to the class: Explain the problem caused by the habitat change, share their proposed solutions, and highlight how AI insights helped shape their ideas."
                                primaryTypographyProps={{ variant: 'h6', color: 'primary' }}
                            />
                        </ListItem>
                    </List>
                </Box>
            </Box>
        </>
    );
};

export default MetacognitiveExerciseExploringHabitatsWithAi;
