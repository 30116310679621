import React, { useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Stack,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Fade,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TargetIcon from '@mui/icons-material/TrackChanges';

import {
  Book as BookIcon,
  SmartToy as AIIcon,
  Timer as TimerIcon,
  Group as GroupIcon,
  Chat as DiscussionIcon,
  Assignment as AssignmentIcon,
  Create as WritingIcon,
  Sync as SwapIcon,
  CheckCircle as CheckIcon,
  MenuBook as MenuBookIcon,
  Edit as EditIcon,
  People as PeopleIcon,
  QuestionAnswer as QuestionAnswerIcon,
  Feedback as FeedbackIcon,
  Assessment as AssessmentIcon,
  Lightbulb as LightbulbIcon
} from '@mui/icons-material';

// Icon mapping for different section types
const iconMapping = {
  TargetIcon: <TargetIcon />,
  BookIcon: <BookIcon />,
  AIIcon: <AIIcon />,
  TimerIcon: <TimerIcon />,
  GroupIcon: <GroupIcon />,
  DiscussionIcon: <DiscussionIcon />,
  AssignmentIcon: <AssignmentIcon />,
  WritingIcon: <WritingIcon />,
  SwapIcon: <SwapIcon />,
  CheckIcon: <CheckIcon />,
  MenuBookIcon: <MenuBookIcon />,
  EditIcon: <EditIcon />,
  PeopleIcon: <PeopleIcon />,
  QuestionAnswerIcon: <QuestionAnswerIcon />,
  FeedbackIcon: <FeedbackIcon />,
  AssessmentIcon: <AssessmentIcon />,
  LightbulbIcon: <LightbulbIcon />
};

const LessonContentRenderer = ({ lessonData, keyStage, className, setSelectedLesson }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [currentLesson, setCurrentLesson] = useState(lessonData[0]);
  if (!lessonData) {
    return null;
  }

  const handleLessonChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentLesson(lessonData[newValue]);
    
    // If setSelectedLesson prop exists, call it
    if (setSelectedLesson) {
      setSelectedLesson(lessonData[newValue]);
    }
  };


  // Helper function to get the appropriate icon
  const getIcon = (iconName) => {
    return iconMapping[iconName] || <LightbulbIcon />;
  };

  // Render lesson plan sections
  const renderLessonPlanSections = (sections) => {
    return sections.map((section, index) => (
      <Accordion key={index} defaultExpanded={index === 0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            background: 'rgba(76, 129, 242, 0.05)',
            '&.Mui-expanded': {
              background: 'rgba(76, 129, 242, 0.1)',
            }
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Chip
              label={`Part ${section.part}`}
              color="primary"
              size="small"
              sx={{ fontWeight: 'bold' }}
            />
            <Typography variant="h6">{section.name}</Typography>
            <Chip
              label={`${section.duration} min`}
              color="secondary"
              size="small"
              variant="outlined"
            />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {section.activities.map((activity, actIndex) => (
              <ListItem key={actIndex} sx={{ mb: 1, alignItems: 'flex-start' }}>
                <ListItemIcon sx={{ mt: 0.5 }}>
                  {getIcon(activity.icon)}
                </ListItemIcon>
                <ListItemText
                  primary={<Typography variant="body1" fontWeight="bold">{activity.primary}</Typography>}
                  secondary={activity.secondary}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>
    ));
  };

  // Render content sections (like objectives, assessment criteria, etc.)
  const renderContentSections = (content) => {
    return content.map((section, index) => (
      <Card key={index} sx={{ mb: 3, borderLeft: '4px solid #4c81f2' }}>
        <CardContent>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
            <Box sx={{ color: '#4c81f2' }}>
              {getIcon(section.icon)}
            </Box>
            <Typography variant="h6">{section.title}</Typography>
          </Stack>
          <Typography variant="subtitle1" color="primary" sx={{ mb: 1 }}>
            {section.sectionName}
          </Typography>
          <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
            {section.description}
          </Typography>
        </CardContent>
      </Card>
    ));
  };


  const tabs = (
    <Box sx={{ mb: 10 }}>
      <FormControl fullWidth variant="outlined" sx={{ maxWidth: 500 }}>
        <InputLabel id="lesson-select-label">Select Lesson</InputLabel>
        <Select
          labelId="lesson-select-label"
          id="lesson-select"
          value={selectedTab}
          onChange={(e) => handleLessonChange(e, e.target.value)}
          label="Select Lesson"
          sx={{
            fontFamily: 'Poppins',
            '& .MuiSelect-select': {
              background: selectedTab !== '' ? 'linear-gradient(90deg, #4c81f2, #a670bd, #a670bd)' : 'inherit',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: selectedTab !== '' ? 'transparent' : 'inherit',
            }
          }}
        >
          {lessonData.map((lesson, index) => (
            <MenuItem
              key={index}
              value={index}
              sx={{ fontFamily: 'Poppins' }}
            >
              Lesson {lesson.metadata.lesson}: {lesson.metadata.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );

  function lessonPlan() {
    return (
      <Box>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Typography variant="h4" sx={{ mb: 2 }}>
          {currentLesson.metadata.title} - Key Stage {currentLesson.metadata.keyStage}
        </Typography>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {currentLesson.metadata.module}
        </Typography>
        <Typography variant="h6" color="primary">
          Lesson {currentLesson.metadata.lesson}: {currentLesson.metadata.title}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" fontWeight="bold">Curriculum Alignment:</Typography>
          <Typography variant="body1">{currentLesson.metadata.curriculumAlignment}</Typography>
        </Box>
      </Paper>

      <Fade in={true} timeout={500}>
        <Box>
          {currentLesson.components.map((component, compIndex) => (
            <Paper key={compIndex} elevation={2} sx={{ mb: 4, p: 3, borderRadius: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  mb: 2,
                  pb: 1,
                  borderBottom: '2px solid',
                  borderImage: 'linear-gradient(90deg, #4c81f2, #a670bd) 1',
                  fontWeight: 'bold'
                }}
              >
                {component.name}
              </Typography>

              {component.componentType === 'lessonplanObjectives' && (
                <>
                  {renderContentSections(component.content)}

                  {component.lessonPlan && (
                    <Box sx={{ mt: 4 }}>
                      <Typography variant="h6" sx={{ mb: 2 }}>Lesson Plan</Typography>
                      {renderLessonPlanSections(component.lessonPlan.sections)}
                    </Box>
                  )}
                </>
              )}

              {component.componentType === 'assessment' && (
                <>
                  {renderContentSections(component.content)}
                </>
              )}

              {component.componentType === 'reflection' && (
                <>
                  {renderContentSections(component.content)}
                </>
              )}
            </Paper>
          ))}
        </Box>
      </Fade>
    </Box >
  )
}

return (
  <Box sx={{ p: 0 }}>
    {tabs}
    {currentLesson && lessonPlan(currentLesson)}
  </Box>
)};

export default LessonContentRenderer; 