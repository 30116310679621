import { signOut } from "firebase/auth";
import { collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where, writeBatch } from "firebase/firestore";
import { toast } from "react-toastify";
import { auth, db } from "../config/firebase";
import { enums, modules, componentMapping } from "../utils/enums";
import { similarityCheck } from "../utils/similarityCheck";
import createContext from "./createContext";

const appReducer = (state, action) => {
    switch (action.type) {
        case 'update_students':
            // Remove duplicates by uid
            const uniqueStudents = [...state.students];

            action.payload.forEach(newStudent => {
                const existingIndex = uniqueStudents.findIndex(student => student.uid === newStudent.uid);
                if (existingIndex >= 0) {
                    uniqueStudents[existingIndex] = newStudent;
                } else {
                    uniqueStudents.push(newStudent);
                }
            });
            return { ...state, students: uniqueStudents }
            
        case 'update_interns':
            // Remove duplicates by uid
            const uniqueInterns = [...state.interns];
            console.log('uniqueInterns')
            console.log(uniqueInterns)
            console.log(action.payload)
            if (action.payload) {
                console.log(action.payload);
                
                // Check if action.payload is an array, if not, wrap it in an array
                const payloadArray = Array.isArray(action.payload) ? action.payload : [action.payload];
                
                payloadArray.forEach(newIntern => {
                    const existingIndex = uniqueInterns.findIndex(intern => intern.uid === newIntern.uid);
                    if (existingIndex >= 0) {
                        uniqueInterns[existingIndex] = newIntern;
                    } else {
                        uniqueInterns.push(newIntern);
                    }
                });
            }
            return { ...state, interns: uniqueInterns }
        case 'update_applicants':
            return { ...state, applicants: action.payload }
        case 'update_mentors':
            return { ...state, mentors: action.payload }
        case 'update_internships':
            return { ...state, internships: action.payload }
        case 'update_internship':
            let newInternships = [...state.internships]
            const internshipIndex = newInternships?.findIndex(internship => internship.uid === action.payload.uid);
            if (isFinite(internshipIndex) && internshipIndex >= 0) {
                newInternships[internshipIndex] = action.payload;
            } else {
                newInternships = [...newInternships, action.payload];
            }
            return { ...state, internships: newInternships }
        case 'update_user':
            return { ...state, currentUser: action.payload }
        case 'update_ideas':
            return { ...state, ideas: action.payload }
        case 'update_messages':
            return { ...state, messages: action.payload }
        case 'update_idea':
            let newIdeas = [...state.ideas]
            const index = newIdeas?.findIndex(idea => idea.uid === action.payload.uid);
            if (isFinite(index) && index >= 0) {
                newIdeas[index] = action.payload;
            } else {
                newIdeas = [...newIdeas, action.payload];
            }
            return { ...state, ideas: newIdeas }
        case 'update_gpt':
            let newGPTs = [...state.GPTS]
            const gptIndex = newGPTs?.findIndex(gpt => gpt.uid === action.payload.uid);
            if (isFinite(gptIndex) && gptIndex >= 0) {
                newGPTs[gptIndex] = action.payload;
            } else {
                newGPTs = [...newGPTs, action.payload];
            }
            return { ...state, GPTS: newGPTs }
        case 'update_gpts':
            return { ...state, GPTS: [...action.payload] }
        case 'delete_gpt':
            const gptsWithoutDeleted = state.GPTS.filter(gpt => gpt.uid !== action.payload);
            return { ...state, GPTS: gptsWithoutDeleted }
        case 'create_gpt':
            const createdGptAndNewGPTs = [...state.GPTS, action.payload];
            return { ...state, GPTS: createdGptAndNewGPTs }

        default:
            return state;
    }
}

const createOrUpdateIdea = (dispatch) => {
    return async (data) => {
        try {
            await setDoc(doc(db, "ideas", data.uid), data);
            dispatch({ type: 'update_idea', payload: data });
            toast.success("Idea uploaded successfully.")
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const updateIdeas = (dispatch) => {
    return async (data) => {
        try {
            dispatch({ type: 'update_ideas', payload: data });
            toast.success("Idea uploaded successfully.")
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const updateMessages = (dispatch) => {
    return async (data) => {
        try {
            dispatch({ type: 'update_messages', payload: data });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const fetchIdeas = (dispatch) => {
    return async () => {
        try {
            let items = [];
            const querySnapshot = await getDocs(collection(db, "ideas"));
            querySnapshot.forEach((doc) => {
                items.push({
                    uid: doc.uid,
                    ...doc?.data()
                });
            });
            items = items.sort((a, b) => b.createdAt - a.createdAt);
            dispatch({ type: "update_ideas", payload: items });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const getUserData = (dispatch) => {
    return async () => {
        console.log('getting user data')
        try {
            const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
            console.log(userDoc.data())
            if (!userDoc.data()) return signOut(auth)
            getMentors()
            dispatch({ type: "update_user", payload: userDoc.data() });

        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const getMentors = (dispatch) => {
    return async () => {
        const mentorsQuery = query(
            collection(db, "users"),
            where("role", "==", enums.MENTOR),
            where("isMentor", "==", true)
        );
        console.log('getting mentors')
        const mentorsSnapshot = await getDocs(mentorsQuery);

        let mentorsData = []
        mentorsSnapshot.docs.map(doc => (
            mentorsData.push(
                {
                    uid: doc.data().uid,
                    name: doc.data().name,
                    jobTitle: doc.data().jobTitle,
                    jobDescription: doc.data().jobDescription,
                    location: doc.data().location,
                    company: doc.data().company,
                    linkedIn: doc.data().linkedIn,
                })))

        console.log('mentorsData')
        console.log(mentorsData)
        dispatch({ type: "update_mentors", payload: mentorsData });
    }
}

const getInterns = (dispatch) => {
    return async (internUids) => {
        console.log('getting interns')
        console.log(internUids)
        try {
            if (!internUids || !internUids.length) {
                dispatch({ type: "update_interns", payload: [] });
                return;
            }

            // Get all student documents where uid is in the studentUids array
            const internQuery = query(
                collection(db, "users"),
                where("role", "==", enums.INTERN),
                where("uid", "in", internUids)
            );

            console.log('getting interns')
            const internSnapshot = await getDocs(internQuery);

            let internsData = []
            internSnapshot.docs.map(doc => (
                internsData.push({
                    uid: doc.data().uid,
                    name: doc.data().name,
                    jobTitle: doc.data().jobTitle,
                    jobDescription: doc.data().jobDescription,
                    location: doc.data().location,
                    school: doc.data().school,
                    internshipUid: doc.data().internshipUid
                }))
            );

            console.log('interns data:', internsData)
            dispatch({ type: "update_interns", payload: internsData });
        } catch (err) {
            console.error('Error fetching interns:', err);
            toast.error(err.message || 'Failed to fetch interns');
            dispatch({ type: "update_interns", payload: [] });
        }
    }
}

const updateInternship = (dispatch) => {
    return async (data) => {
        console.log('updating internship')
        console.log(data.uid)
        const uid = data.uid
        await updateDoc(doc(db, "internships", uid), data);
        dispatch({ type: "update_internship", payload: data });
    }
}

const getApplicants = (dispatch) => {
    return async (currentUserUid) => {
        try {
            if (!currentUserUid) {
                dispatch({ type: "update_applicants", payload: [] });
                return;
            }

            // Get all student documents where uid is in the studentUids array
            const applicantsquery = query(
                collection(db, "users"),
                where("role", "==", enums.INTERN),
                where("mentorApplications", "array-contains", currentUserUid)
            );

            console.log('getting applicants')
            const studentsSnapshot = await getDocs(applicantsquery);

            let applicantsData = []
            studentsSnapshot.docs.map(doc => (
                applicantsData.push({
                    uid: doc.data().uid,
                    name: doc.data().name,
                    jobTitle: doc.data().jobTitle,
                    jobDescription: doc.data().jobDescription,
                    location: doc.data().location,
                    school: doc.data().school,
                }))
            );

            console.log('students data:', applicantsData)
            dispatch({ type: "update_applicants", payload: applicantsData });
        } catch (err) {
            console.error('Error fetching students:', err);
            toast.error(err.message || 'Failed to fetch students');
            dispatch({ type: "update_students", payload: [] });
        }
    }
}

const getInternships = (dispatch) => {
    return async (data) => {
        try {
            console.log('getting internships');
            console.log(data);
            if (!data?.uid) {
                dispatch({ type: "update_internships", payload: [] });
                return;
            }
            console.log('getting internships');
            console.log(data);
            // Create query based on user type
            const internshipsQuery = query(
                collection(db, "internships"),
                where(data.type === enums.MENTOR ? 'mentorUid' : 'studentUid', "==", data.uid)
            );

            console.log('getting internships snapshot');
            const snapshot = await getDocs(internshipsQuery);
            console.log('getting internships snapshot1');
            let internshipsData = [];
            console.log(snapshot.docs);
            snapshot.docs.forEach(doc => {
                internshipsData.push({ ...doc.data() });
            });

            dispatch({ type: "update_internships", payload: internshipsData });
        } catch (err) {
            console.error('Error fetching internships:', err);
            toast.error(err.message || 'Failed to fetch internships');
            dispatch({ type: "update_internships", payload: [] });
        }
    }
}

const updateUserData = (dispatch) => {
    return async (data) => {
        try {
            console.log('data')
            console.log(data)
            await updateDoc(doc(db, "users", auth.currentUser.uid), data);
            dispatch({ type: "update_user", payload: data });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const updateInternData = (dispatch) => {
    return async (data) => {
        try {
            console.log('data')
            console.log(data)
            await updateDoc(doc(db, "users", data.uid), data);
            dispatch({ type: "update_interns", payload: data });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const createInternship = (dispatch) => {
    return async (data) => {
        try {
            await setDoc(doc(db, "internships", data.uid), data);
            dispatch({ type: "update_internships", payload: data });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const updateIdeaValues = (dispatch) => {
    return async (data) => {
        try {
            dispatch({ type: 'update_idea', payload: data });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed please try again!");
        }
    }
}

const runSimilarityCheck = (dispatch) => {
    return async (id) => {
        try {
            toast.info("Running similarity check please wait...")
            let items = [];
            const querySnapshot = await getDocs(collection(db, "ideas"));
            querySnapshot.forEach((doc) => {
                if (doc.uid !== id) {
                    items.push({
                        uid: doc.uid,
                        ...doc?.data()
                    });
                }
            });
            const docRef = doc(db, "ideas", id);
            const docSnap = await getDoc(docRef);
            console.log(items)
            if (docSnap.exists()) {
                const primaryIdea = docSnap.data();
                const ideas = await similarityCheck(primaryIdea, items);
                console.log("similarity check", ideas)
                const batch = writeBatch(db);
                for (let i = 0; i < ideas?.length; i++) {
                    const idea = ideas[i];
                    const ref = doc(db, "ideas", idea?.uid);
                    batch.update(ref, { ...idea })
                }
                await batch.commit();
                fetchIdeas(dispatch)();

                toast.success("Similarity check is done.")
            } else {
                // docSnap.data() will be undefined in this case
                console.log("No such document!");
                throw Error("Idea not found")
            }
        } catch (err) {
            toast.error(err?.message || err);
            // throw Error("Failed please try again!");
        }
    }
}

const createGPT = (dispatch) => {
    return async (data) => {
        try {
            const gptData = {
                ...data,
                createdBy: auth.currentUser.uid,
                createdAt: new Date().getTime()
            };
            await setDoc(doc(db, "gpts", data.uid), gptData);
            dispatch({ type: 'create_gpt', payload: gptData });
            toast.success("GPT created successfully.");
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed to create GPT. Please try again!");
        }
    }
}

const updateGPT = (dispatch) => {
    return async (data) => {
        try {
            await updateDoc(doc(db, "gpts", data.uid), data);
            dispatch({ type: 'update_gpt', payload: data });
            toast.success("GPT updated successfully.");
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed to update GPT. Please try again!");
        }
    }
}

const deleteGPT = (dispatch) => {
    return async (uid) => {
        try {
            await deleteDoc(doc(db, "gpts", uid));
            dispatch({ type: 'delete_gpt', payload: uid });
            toast.success("GPT deleted successfully.");
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed to delete GPT. Please try again!");
        }
    }
}

const fetchGPTs = (dispatch) => {
    return async () => {
        try {
            let items = [];
            // Add query to filter by current user's uid
            const gptsQuery = query(
                collection(db, "gpts"),
                where("createdBy", "==", auth.currentUser.uid)
            );
            const querySnapshot = await getDocs(gptsQuery);
            querySnapshot.forEach((doc) => {
                items.push({
                    uid: doc.id,
                    ...doc?.data()
                });
            });
            dispatch({ type: "update_gpts", payload: items });
        } catch (err) {
            toast.error(err);
            console.log(err);
            throw Error("Failed to fetch GPTs. Please try again!");
        }
    }
}

export const { Provider, Context } = createContext(
    appReducer,
    {
        fetchIdeas,
        createOrUpdateIdea,
        updateIdeaValues,
        runSimilarityCheck,
        updateIdeas,
        updateMessages,
        getUserData,
        updateUserData,
        getMentors,
        getInterns,
        getApplicants,
        createInternship,
        getInternships,
        updateInternship,
        updateInternData,
        createGPT,
        updateGPT,
        deleteGPT,
        fetchGPTs
    },
    {
        currentUser: null,
        mentors: [],
        interns: [],
        ideas: [],
        messages: [],
        GPTS: [],
        modules: modules,
        componentMapping: componentMapping
    }
)


